import React from "react";
import Typography from "@mui/material/Typography";

function Step1Header() {
  return (
    <>
      <Typography paragraph={true}>
        Contact details of local surveyors will appear in a few seconds from our
        partners Local Surveyors Direct.
      </Typography>
      <Typography paragraph={true}>
        You will be provided with a list of local surveyors who can carry out
        your survey together with a price for the job and their contact details.
        If you wish, you can contact any of these surveyors directly, or they
        may contact you for more information so they can give a more precise
        quote.
      </Typography>
      <Typography paragraph={true}>
        To calculate the price for your survey, your property details are only
        provided to relevant surveyors who cover your area, along with your
        email and phone number. If you do not want to give your phone number
        please put 0000 in this field.
      </Typography>
      <Typography paragraph={true}>
        Local Surveyors Direct will NOT use your contact details again, other
        than a brief feedback email to check that you were happy with the
        service provided. Please read{" "}
        <a
          href="https://www.localsurveyorsdirect.co.uk/customer-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          LSD&apos;s Privacy Policy
        </a>{" "}
        for more information.
      </Typography>
      <Typography paragraph={true}>
        The HomeOwners Alliance will NOT use your contact details unless you
        have opted in to receive our free newsletter. Please read{" "}
        <a
          href="https://hoa.org.uk/our-site/home-owners-alliance-privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          HOA&apos;s Privacy Policy
        </a>{" "}
        for more information.
      </Typography>
    </>
  );
}

export default Step1Header;
